import { takeLatest, all, put, call } from "redux-saga/effects";
import { get } from "../../services/httpService";
import environment from "../../config/environment";
import {
  appConfigFailure,
  appConfigSuccess,
  APP_CONFIG_REQUEST,
} from "../Actions/appConfigActions";
import { normalizeData } from "../Actions/normalizrActions";
import { websiteConfigSchema } from "../Schemas/websiteConfigSchema";
import { supplierSettingsSuccess } from "../Actions/supplierSettingsActions";

function* handleAppConfigRequest(action) {
  try {
    const { domain } = action.payload;
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/websiteconfigs/domain`,
      {
        params: {
          domain,
          relationships: ["supplier"],
        },
      }
    );
    const config = response.data;
    const settingsResponse = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/suppliers/${config.supplier._id}/settings`
    );
    yield put(normalizeData(config, websiteConfigSchema));
    yield put(appConfigSuccess(config._id));
    yield put(
      supplierSettingsSuccess({
        ...settingsResponse.data,
      })
    );
  } catch (err) {
    yield put(appConfigFailure(err));
    console.log(err);
  }
}

export default function* appConfigSaga() {
  yield all([takeLatest(APP_CONFIG_REQUEST, handleAppConfigRequest)]);
}
