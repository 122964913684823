import {
  GET_PRODUCTS_SHOP_REQUEST,
  GET_PRODUCTS_SHOP_SUCCESS,
  GET_ACTIVITIES_SUCCESS,
} from "../actions/shopActions";

const INITIAL_STATE = {
  results: [],
  activities: [],
  isFetching: false,
  error: false,
};

const productsShopReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCTS_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    case GET_PRODUCTS_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        results: payload.results,
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        activities: payload.results,
      };
    default:
      return state;
  }
};

export default productsShopReducer;
