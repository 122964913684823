import styled from "styled-components";
import Select from "antd/es/select";

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  font-size: 20px;
  color: ${({ theme, disabled }) =>
    disabled ? "#00000040" : theme.colors.primaryColor};
  z-index: 1;
  left: 15px;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borders};
  border-radius: 20px;
  background-color: white;
  color: ${({ theme }) => theme.colors.fonts};

  .ant-select-selector {
    padding-left: ${({ $hasIcon }) => $hasIcon && "45px"} !important;
  }
`;

export const DropdownOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 100px;
  align-items: center;
`;
