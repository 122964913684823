import styled from "styled-components";
import Text from "antd/es/typography/Text";

export const Container = styled.div`
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 18px -8px rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  display: flex;
  gap: 15px;
  width: 100%;
  position: relative;
  border-radius: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledTitle = styled(Text)`
  margin-top: 5px;
`;
