import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  calculateItemsLength,
  normalizeProducts,
  toISOFormatString,
} from "../../../../helpers/tools";
import { getProducts } from "../../../../Redux/Selectors/productSelectors";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { performTransactionRequest } from "../../../TransactionPage/TransactionActions";
import { addPassengerRequiredInfo } from "../../redux/actions/shopActions";
import {
  checkPassengersRequiredInfo,
  getAssigmentsData,
  getCheckoutSummary,
  getNormalizePassengers,
  getPassengersData,
  getSummary,
} from "../../redux/selectors/ShopSelectors";
import { getTransactionStatus } from "../../redux/selectors/TransactionStatusSelectors";
import { getSupplierSettings } from "../../../../Redux/Selectors/appConfigSelector";
import { listTypes, sanitizeVariantListing } from "../../utils";
import useCalculateDates from "../../../../hooks/useCalculateDays";
import useDiscounts from "../PricingContainer/hooks/useDiscounts";

const CheckoutContainer = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const transactionStatus = useSelector(getTransactionStatus);
  const searchValues = useSelector(getSearchValues);
  const quoteSummary = useSelector(getSummary);
  const passengersData = useSelector(getPassengersData);
  const settings = useSelector(getSupplierSettings);
  const checkoutSummary = useSelector(getCheckoutSummary);
  const assignmentsData = useSelector(getAssigmentsData);
  const normalizePassengers = useSelector(getNormalizePassengers);
  const selectedProducts = useSelector((state) =>
    getProducts(state, normalizeProducts(quoteSummary?.products))
  );
  const { discountCode } = useDiscounts();
  const navigate = useNavigate();
  const { from, to, pricingFrom, pricingTo } = useCalculateDates({
    fromDate: params.from,
    toDate: params.to,
    fromTime: params.pickupTime,
    toTime: params.dropoffTime,
    period: quoteSummary.period,
  });

  useEffect(() => {
    if (transactionStatus.status === "PREAUTHORIZED") {
      navigate(
        `/transaction/${transactionStatus._id}/pay?customerId=${transactionStatus.data.guest}`,
        { replace: true }
      );
    }
  }, [transactionStatus]);
  const checkPassengers = useSelector(checkPassengersRequiredInfo);

  const handleOnRequestPayment = () => {
    const sanitizedVariants = sanitizeVariantListing(
      assignmentsData,
      listTypes.PRODUCT
    );
    const pickAndDrop = {
      pickup: {
        date: toISOFormatString(from),
        time: params.pickupTime,
      },
      dropoff: {
        date: toISOFormatString(to),
        time: params.dropoffTime,
      },
    };

    dispatch(
      performTransactionRequest(
        sanitizedVariants,
        toISOFormatString(pricingFrom),
        toISOFormatString(pricingTo),
        quoteSummary.period,
        settings.currency,
        { passengers: normalizePassengers },
        pickAndDrop,
        discountCode
      )
    );
  };

  const handleOnInfoSubmit = (data) => {
    dispatch(addPassengerRequiredInfo(data));
  };

  return children({
    quoteSummary,
    passengersData,
    checkoutSummary,
    handleOnRequestPayment,
    handleOnInfoSubmit,
    checkPassengers,
    isBookingFetching: transactionStatus.fetching,
    selectedProducts,
    itemsLength: calculateItemsLength(quoteSummary?.products),
    searchValues,
  });
};

export default CheckoutContainer;
