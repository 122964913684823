import React from "react";
import Form from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import { useTranslation } from "react-i18next";
import IdTypeSelect from "../IdTypeSelect/IdTypeSelect";

const PersonalIdInput = ({
  typeWidth = 150,
  required = false,
  initialTypeValue,
  customerType,
  showLabel = true,
}) => {
  const { t } = useTranslation("shop");

  const idType = (
    <Form.Item
      name="type"
      initialValue={initialTypeValue}
      rules={[
        {
          required,
        },
      ]}
      noStyle
    >
      <IdTypeSelect width={typeWidth} customerType={customerType} />
    </Form.Item>
  );
  return (
    <Form.Item
      name="number"
      label={showLabel && t("form.personalId")}
      rules={[
        {
          required,
          message: t("form.error", { field: t("form.personalId") }),
        },
      ]}
    >
      <Input addonBefore={idType} size="large" />
    </Form.Item>
  );
};

export default PersonalIdInput;
