import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSearchValueByKey } from "../../../../../Redux/Selectors/searchSelectors";
import CalendarInputWrapper from "./CalendarInputWrapper";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../../../Redux/Selectors/appConfigSelector";
import moment from "moment";
import Text from "antd/es/typography/Text";
import BootstrapIcon from "../../../../../components/BootstrapIcon/BoostrapIcon";
import { useTranslation } from "react-i18next";
import { PERIODS, createUTCDate } from "../../../../../helpers/tools";
import {
  RENTALS,
  isAnyHighMountain,
  isRental,
} from "../../../../../helpers/rentals";
import { calculatePrevDaysForBooking } from "../../../utils";
import { isEmpty } from "lodash";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px 0px 10px;
  gap: 15px;
  background-color: white;
  border-bottom: ${({ theme }) => `2px solid ${theme.borders.gray}`};
  border-top: ${({ theme }) => `2px solid ${theme.borders.gray}`};
`;

const Disclaimer = styled.div``;
const StyledText = styled(Text)`
  font-size: 14px;
  margin-right: 10px;
`;

const CalendarInput = ({
  handleShowModal,
  containerStyle,
  bodyStyle,
  bordered = true,
  clickable = true,
  shadow = true,
  handleOnSelect,
  period = PERIODS.day,
  prevDaysForBooking,
}) => {
  const { t } = useTranslation("shop");
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const supplierData = useSelector(getSupplierData);
  const settings = useSelector(getSupplierSettings);
  const isLabarraca = isRental(supplierData._id, RENTALS.labarraca);
  const { from, to } = dates;

  // High Mountain Config
  const isFromLimited =
    createUTCDate(from).isSameOrAfter(createUTCDate("2023-09-15"), "day") &&
    createUTCDate(from).isSameOrBefore(createUTCDate("2023-09-17"), "day");
  const isToLimited =
    createUTCDate(to).isSameOrAfter(createUTCDate("2023-09-15"), "day") &&
    createUTCDate(to).isSameOrBefore(createUTCDate("2023-09-17"), "day");

  const fromHours = JSON.parse(supplierData.openingHours)[
    `hours_${moment(from).format("dddd").toLocaleLowerCase()}`
  ];
  const toHours = JSON.parse(supplierData.openingHours)[
    `hours_${moment(to).format("dddd").toLocaleLowerCase()}`
  ];

  const calculateFromHours = () => {
    if (
      isFromLimited &&
      isRental(supplierData._id, RENTALS.highmountain_santiago)
    ) {
      return [
        {
          ...fromHours[0],
          from: "10:00",
          to: "14:00",
        },
      ];
    }
    return fromHours;
  };

  const calculateToHours = () => {
    if (
      isToLimited &&
      isRental(supplierData._id, RENTALS.highmountain_santiago)
    ) {
      return [
        {
          ...toHours[0],
          from: "10:00",
          to: "14:00",
        },
      ];
    }
    return toHours;
  };

  const handleOnTimeChange = (key, val) => {
    handleOnSelect(key, val);
  };

  const renderDays = (description) => {
    if (isAnyHighMountain(supplierData._id)) {
      return description;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        ...containerStyle,
      }}
    >
      <Container
        style={{ ...bodyStyle }}
        bordered={bordered}
        clickable={clickable}
        shadow={shadow}
      >
        <CalendarInputWrapper
          onClick={() => handleShowModal("calendar")}
          from={calculatePrevDaysForBooking(from, prevDaysForBooking, period)}
          to={calculatePrevDaysForBooking(to, prevDaysForBooking, period)}
          bordered={false}
          align="left"
          handleOnTimeChange={handleOnTimeChange}
          fromHours={calculateFromHours()}
          toHours={calculateToHours()}
          period={period}
        />
      </Container>
      {period === PERIODS.day && !isLabarraca && (
        <div
          style={{
            marginTop: 15,
            marginLeft: 15,
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            display: "flex",
            gap: 10,
            flexDirection: "column",
          }}
        >
          <Disclaimer>
            <div>
              <StyledText type="secondary">
                <BootstrapIcon iconName="clock" />{" "}
                {t("shop:freeChargePickupTime")}{" "}
              </StyledText>
              <br />
              {!isEmpty(settings?.pickupTime) && (
                <StyledText strong>
                  {renderDays("Lunes a Viernes")} {settings.pickupTime[0]}hs -{" "}
                  {settings.pickupTime[1]}hs
                  <br />
                  {renderDays("Sábados: 10:00hs - 14:00hs")}
                </StyledText>
              )}
            </div>
          </Disclaimer>
          <Disclaimer>
            <div>
              <StyledText type="secondary">
                <BootstrapIcon iconName="clock" />{" "}
                {t("shop:freeChargeDropoffTime")}{" "}
              </StyledText>
              <br />
              {!isEmpty(settings?.dropoffTime) && (
                <StyledText strong>
                  {renderDays("Lunes a Sábados")} {settings.dropoffTime[0]}hs -{" "}
                  {settings.dropoffTime[1]}hs
                </StyledText>
              )}
            </div>
          </Disclaimer>
        </div>
      )}
    </div>
  );
};

export default CalendarInput;
