import { useDispatch, useSelector } from "react-redux";
import {
  getAssigmentsData,
  getAssignmentFailure,
  getNormalizePassengers,
  getShopProducts,
  getSummary,
} from "../../ShopPage/redux/selectors/ShopSelectors";
import { useEffect } from "react";
import {
  assignDefaultVariantToPassenger,
  getAllProductsShopRequest,
  unassignVariantToPassenger,
} from "../../ShopPage/redux/actions/shopActions";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../Redux/Selectors/appConfigSelector";
import { isProductsFetching } from "../../QuotePage/redux/QuoteSelectors";
import { performTransactionRequest } from "../../TransactionPage/TransactionActions";
import { toISOFormatString } from "../../../helpers/tools";
import { listTypes, sanitizeVariantListing } from "../../ShopPage/utils";
import useCalculateDates from "../../../hooks/useCalculateDays";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { getMainMemberInfo } from "../../ShopPage/redux/selectors/MainMemberSelectors";
import { getTransactionStatus } from "../../ShopPage/redux/selectors/TransactionStatusSelectors";
import { useState } from "react";
import { ITEM_ACTIONS } from "../constants";
import { notification } from "antd";
import { transactionStatus as transactionStatusConstants } from "../../TransactionPage/helpers";
import { createUTCDate, PRINT_FORMATS } from "../../../helpers/dates";

const ProductsContainer = ({ children }) => {
  const supplierData = useSelector(getSupplierData);
  const assignmentsData = useSelector(getAssigmentsData);
  const assignmentFailure = useSelector(getAssignmentFailure);
  const [showAssignmentModal, setShowAssignmentModal] = useState({
    visible: false,
    action: null,
    productId: null,
    selectedValues: null,
  });
  const [quantityDays, setQuantityDays] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);
  const quoteSummary = useSelector(getSummary);
  const settings = useSelector(getSupplierSettings);
  const transactionStatus = useSelector(getTransactionStatus);
  const mainMember = useSelector(getMainMemberInfo);
  const normalizePassengers = useSelector(getNormalizePassengers);
  const dispatch = useDispatch();
  const products = useSelector((state) =>
    getShopProducts(state, null, null, null, false)
  );
  const productsFetching = useSelector(isProductsFetching);

  const { from, to, pricingFrom, pricingTo } = useCalculateDates({
    fromDate: params.from,
    toDate: createUTCDate(params.from)
      .add(quantityDays - 1, quoteSummary.period)
      .format(PRINT_FORMATS.day.isoFormat),
    period: quoteSummary.period,
  });

  useEffect(() => {
    dispatch(
      getAllProductsShopRequest(
        {
          owner: supplierData._id,
          published: true,
          active: true,
        },
        settings?.currency
      )
    );
  }, []);

  useEffect(() => {
    if (transactionStatus.status === transactionStatusConstants.ACCEPTED) {
      navigate(`/checkin/done`, { replace: true });
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (assignmentFailure) {
      notification.error({
        message: "Hubo un problema",
        description: "No pudimos agregar el producto",
      });
    }
  }, [assignmentFailure]);

  const handleSubmitTransaction = () => {
    const autoConfirm = true;
    const sanitizedVariants = sanitizeVariantListing(
      assignmentsData,
      listTypes.PRODUCT
    );
    const pickAndDrop = {
      pickup: {
        date: toISOFormatString(from),
        time: params.pickupTime,
      },
      dropoff: {
        date: toISOFormatString(to),
        time: params.dropoffTime,
      },
    };

    dispatch(
      performTransactionRequest(
        sanitizedVariants,
        toISOFormatString(pricingFrom),
        toISOFormatString(pricingTo),
        quoteSummary.period,
        settings.currency,
        { passengers: normalizePassengers },
        pickAndDrop,
        null,
        mainMember.referral,
        "checkin",
        mainMember.housingInfo,
        autoConfirm
      )
    );
  };

  const handleOnAddProduct = (productId) => {
    setShowAssignmentModal({
      visible: true,
      action: ITEM_ACTIONS.add,
      productId,
    });
  };

  const handleOnSubstractProduct = (productId) => {
    setShowAssignmentModal({ visible: true, action: "substract", productId });
  };

  const handleAssignmentModalOnClose = () => {
    setShowAssignmentModal(false, { action: null });
  };

  const onAssignMember = (value) => {
    const { productId, action } = showAssignmentModal;
    if (action === ITEM_ACTIONS.add) {
      dispatch(
        assignDefaultVariantToPassenger({
          passenger: value,
          variantId: null,
          productId,
          bundleId: null,
          index: `${value}-${productId}-${
            quoteSummary.products[productId] || 0
          }`,
          type: "",
          experienceId: null,
          fieldkey: "",
          id: "",
        })
      );
    } else {
      dispatch(
        unassignVariantToPassenger({
          index: `${value}-${productId}`,
          productId,
        })
      );
    }
  };

  const handleOnAdd = () => {
    setQuantityDays(quantityDays + 1);
  };

  const handleOnSubtract = () => {
    setQuantityDays(quantityDays - 1);
  };

  return children({
    products,
    quoteSummary,
    productsFetching,
    handleSubmitTransaction,
    transactionStatus,
    handleOnAddProduct,
    handleOnSubstractProduct,
    showAssignmentModal,
    handleAssignmentModalOnClose,
    onAssignMember,
    assignmentsData,
    handleOnSubtract,
    handleOnAdd,
    quantityDays,
  });
};

export default ProductsContainer;
