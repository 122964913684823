import moment from "moment";

export const PERIODS = {
  day: "day",
  hour: "hour",
};

export const FORMATS = {
  short: "short",
  shortest: "shortest",
  long: "long",
  longDate: "longDate",
};

export const PRINT_FORMATS = {
  day: {
    short: "DD/MM/YYYY",
    shortest: "DD/MM",
    long: "DD MMM YYYY",
    longDate: "dddd, D MMMM YYYY",
    isoFormat: "YYYY-MM-DD",
  },
  hour: {
    short: "DD/MM/YYYY HH:mm[hs]",
    long: "DD MMM YYYY, HH:mm[hs]",
  },
};

export const isValidPeriod = (period) =>
  Object.values(PERIODS).includes(period);

export const createUTCDate = (dateString, isUTCString = false) => {
  return moment(dateString).utc(!isUTCString);
};

export const parseApiDate = (dateString) => createUTCDate(dateString, true);

export const toISOFormatString = (dateMoment) => {
  if (!dateMoment) return null;
  return renderCustomFormatDate(dateMoment, "YYYY-MM-DDTHH:mm:ss");
};

export const renderDate = (dateMoment, format, period) => {
  if (!dateMoment) return null;
  const printPeriod = isValidPeriod(period) ? period : PERIODS.day;
  return renderCustomFormatDate(
    dateMoment,
    PRINT_FORMATS[printPeriod][format] ||
      PRINT_FORMATS[printPeriod][FORMATS.long]
  );
};

export const printDateTo = (
  toDateMoment,
  referenceDateMoment = createUTCDate()
) => {
  return referenceDateMoment.to(toDateMoment);
};

export const renderCustomFormatDate = (
  dateMoment = createUTCDate(),
  format = ""
) => {
  const date = createUTCDate(dateMoment);
  return date.format(format);
};

export const toDateFromUTC = (dateMoment) =>
  new Date(toISOFormatString(dateMoment));

export const calculateDaysLength = (from, to, period = PERIODS.day) => {
  if (!from || !to) return null;
  return to.diff(from, period) + 1;
};

export const deadlineNotArrived = (deadlineDate) => {
  return createUTCDate().isSameOrBefore(deadlineDate);
};

export const isTimePeriod = (period) => {
  return period === PERIODS.hour || period === PERIODS.ticket;
};

export const isSameDay = (from, to) => {
  return moment(from).isSame(moment(to));
};

export const getDatesInRange = (startDate, endDate) => {
  const sanitizeStartDate = new Date(startDate);
  const sanitizedEndDate = new Date(endDate);
  const date = new Date(sanitizeStartDate.getTime());

  const dates = [];

  while (date <= sanitizedEndDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const generateYearsBack = () => {
  const year = new Date().getFullYear();
  return Array.from({ length: 3 }, (v, i) => year - 3 + i + 1);
};
