import Container from "../../../components/Container/Container";
import QuoteBuilderRow from "../../ShopPage/components/QuoteBuilderRow/QuoteBuilderRow";
import ProductsContainer from "../containers/ProductsContainer";
import { Button } from "../../../components/Button/Button.styles";
import { useTranslation } from "react-i18next";
import { FormContainer, PricingSection } from "../CheckinScreen.styles";
import { Divider, Spin } from "antd";
import { isEmpty } from "lodash";
import Paragraph from "antd/es/typography/Paragraph";
import usePeriod from "../../../hooks/usePeriod";
import AssignmentModal from "../components/AssignmentModal";
import SummaryDetails from "../components/SummaryDetails";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";
import { isVertical, isCircuitoChico } from "../../../helpers/rentals";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import Counter from "../../../components/Counter/Counter";
import { CounterContainer } from "../../../components/Counter/styled";

const ProductsScreen = () => {
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);
  usePeriod();

  const showCounter =
    isVertical(supplierData?._id) || isCircuitoChico(supplierData?._id);

  return (
    <Container>
      <FormContainer>
        <ProductsContainer>
          {({
            products,
            quoteSummary,
            handleSubmitTransaction,
            transactionStatus,
            handleOnSubstractProduct,
            handleOnAddProduct,
            showAssignmentModal,
            handleAssignmentModalOnClose,
            onAssignMember,
            assignmentsData,
            handleOnSubtract,
            handleOnAdd,
            quantityDays,
          }) => {
            if (isEmpty(products) || transactionStatus.fetching) {
              return <Spin />;
            } else {
              return (
                <>
                  {showAssignmentModal.visible && (
                    <AssignmentModal
                      onClose={handleAssignmentModalOnClose}
                      onAssignMember={onAssignMember}
                      action={showAssignmentModal.action}
                      productId={showAssignmentModal.productId}
                      assignmentsData={assignmentsData}
                    />
                  )}
                  <BannerRenderContainer screen="checkInPageToProducts" />
                  {!showCounter && (
                    <CounterContainer>
                      <Paragraph style={{ margin: 0 }}>
                        {t("numberOfDays")}:{" "}
                      </Paragraph>
                      <Counter
                        value={quantityDays}
                        onAdd={handleOnAdd}
                        onSubtract={handleOnSubtract}
                        min={1}
                        max={99}
                      />
                    </CounterContainer>
                  )}

                  <Paragraph style={{ fontSize: 14 }} type="secondary">
                    {t("shop:checkin.products.selectProducts")}
                  </Paragraph>
                  {products?.map((item, index) => (
                    <QuoteBuilderRow
                      index={index}
                      key={item._id}
                      productId={item._id}
                      title={item.title}
                      price={item.convertedPrice}
                      currency={item.convertedCurrency}
                      quantity={quoteSummary.products[item._id] || 0}
                      isClosed={false}
                      supplierName={item.owner?.name}
                      type={item?.type}
                      minimumPeriodQuantity={item.minimumPeriodQuantity}
                      rentalPeriodType={item.rentalPeriodType}
                      category={item.category?.value}
                      showSnackbar={false}
                      handleOnSubstractProduct={handleOnSubstractProduct}
                      handleOnAddProduct={handleOnAddProduct}
                      hidePrice
                    />
                  ))}
                  <Divider />
                  <PricingSection>
                    <SummaryDetails />
                    <Divider />
                  </PricingSection>

                  <Button
                    type="primary"
                    block
                    size="large"
                    disabled={isEmpty(quoteSummary.products)}
                    onClick={handleSubmitTransaction}
                  >
                    {t("common:confirm")}
                  </Button>
                </>
              );
            }
          }}
        </ProductsContainer>
      </FormContainer>
    </Container>
  );
};
export default ProductsScreen;
