import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { Item, Row } from "./PeriodCalendars.styles";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const DaysCalendarInput = ({
  from,
  to,
  align,
  handleOnTimeChange,
  fromHours,
  toHours,
  onClick,
}) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  return (
    <Container>
      <Row mobile={isMobile}>
        <Item mobile={isMobile}>
          <DateInput
            label={t("startDate")}
            value={from}
            align={align}
            onClick={onClick}
          />
        </Item>
        <Item mobile={isMobile}>
          <TimePicker
            label={t("startDate")}
            onChange={handleOnTimeChange}
            keyVal="pickupTime"
            fromTime={fromHours?.map((hour) => hour.from.split(":")[0]).flat()}
            toTime={fromHours?.map((hour) => hour.to.split(":")[0]).flat()}
            dateSelected={from}
          />
        </Item>
        <Item mobile={isMobile}>
          <DateInput
            label={t("dropOffDate")}
            value={to}
            align={align}
            onClick={onClick}
          />
        </Item>
        <Item mobile={isMobile}>
          <TimePicker
            label={t("dropOffDate")}
            onChange={handleOnTimeChange}
            keyVal="dropoffTime"
            fromTime={toHours?.map((hour) => hour.from.split(":")[0]).flat()}
            toTime={toHours?.map((hour) => hour.to.split(":")[0]).flat()}
            dateSelected={to}
          />
        </Item>
      </Row>
    </Container>
  );
};

export default DaysCalendarInput;
