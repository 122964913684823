import styled from "styled-components";
import Form from "antd/es/form";
import Input from "antd/es/input";
import CountrySelector from "../../../../../../components/CountrySelector/CountrySelector";
import PersonalIdInput from "../../../../../../components/FormInputs/PersonalIdInput";
import PhoneInput from "../../../../../../components/FormInputs/PhoneInput";
import mediaQueries from "../../../../../../utils/mediaQueries";

export const StyledForm = styled(Form)`
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media ${mediaQueries.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 10px;
`;

export const StyledInput = styled(Input)`
  min-width: 100px;
  padding-left: 4%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CountrySelect = styled(CountrySelector)`
  .ant-select-selector {
    border-radius: 20px !important;
  }
`;

export const PersonalIdForm = styled(PersonalIdInput)`
  .ant-input {
    border-radius: 0px 20px 20px 0px !important;
  }
  .ant-input-group-addon {
    border-radius: 20px 0px 0px 20px !important;
  }
  .ant-select.ant-select-open .ant-select-selector,
  .ant-select.ant-select-focused .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.colors.fonts};
  }
`;
export const PhoneForm = styled(PhoneInput)`
  .ant-input {
    border-radius: 0px 20px 20px 0px !important ;
  }
  .ant-input-group-addon {
    border-radius: 20px 0px 0px 20px !important;
  }
  .ant-select.ant-select-open .ant-select-selector,
  .ant-select.ant-select-focused .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.colors.fonts};
  }
`;
