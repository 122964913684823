import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingPeriod } from "../features/ShopPage/redux/actions/shopActions";
import { RENTALS, isRental } from "../helpers/rentals";
import { PERIODS } from "../helpers/tools";
import { getSupplierData } from "../Redux/Selectors/appConfigSelector";

const usePeriod = () => {
  const dispatch = useDispatch();
  const supplierData = useSelector(getSupplierData);

  useEffect(() => {
    if (isRental(supplierData._id, RENTALS.ananda)) {
      dispatch(setBookingPeriod(PERIODS.hour));
    } else {
      dispatch(setBookingPeriod(PERIODS.day));
    }
  }, []);
};

export default usePeriod;
