import styled from "styled-components";

export const Item = styled.div`
  padding-left: ${({ mobile }) => (mobile ? "5px" : "20px")};
  border-left: ${({ theme, mobile }) =>
    mobile ? "none" : `1px solid ${theme.borders.gray}`};
  padding-top: 10px;
  border-bottom: ${({ theme, mobile }) =>
    mobile ? `1px solid ${theme.borders.gray}` : "none"};
  padding-bottom: 10px;
  &:nth-child(1) {
    border-left: none;
  }
  &:last-child {
    border-bottom: ${({ mobile }) => mobile && "none"};
  }
  &:first-child {
    padding-left: ${({ mobile }) => (mobile ? "5px" : "0px")};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  justify-content: space-evenly;
  flex: 1;
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  gap: ${({ mobile }) => (mobile ? "0x" : "20px")};
  flex-wrap: wrap;
  align-items: ${({ mobile }) => (mobile ? "none" : "center")};
`;
