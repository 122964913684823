import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import baseTheme from "../../../../config/baseTheme";
import useIsMobile from "../../../../hooks/useIsMobile";
import ProductList from "./ProductList/ProductList";
import SearchBarProducts from "../SearchBar/SearchBarProducts";
import {
  modifyValueSearch,
  setSearchByName,
} from "../../../../Redux/Actions/searchActions";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { clickEvents, trackEvent } from "../../../../services/mixpanel";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import DateInputContainer from "../../containers/DateInputContainer/DateInputContainer";
import { PERIODS, renderCustomFormatDate } from "../../../../helpers/tools";
import { addDays } from "date-fns";
import moment from "moment";
import { getSearchValueByKey } from "../../../../Redux/Selectors/searchSelectors";
import { isRental, RENTALS } from "../../../../helpers/rentals";

const { Paragraph } = Typography;

const Container = styled.div`
  background-color: white;
`;

const SkeletonContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;
  max-width: 600px;
  width: 100%;
`;

const NoProductsContainer = styled.div`
  border: 1px solid ${baseTheme.borders.gray};
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;
`;

const ProductContainer = ({
  categories = [],
  productsByCategory,
  quoteSummary,
  handleOnSubstractProduct,
  handleOnAddProduct,
  handleOnDetailsClick,
  isLoading,
  isClosed,
  itemsLength,
  pricingData,
  isPricingFetching,
  selectedProducts,
  handleOnSummaryClick,
  searchName,
  openingHours,
  discounts,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const supplierData = useSelector(getSupplierData);
  const dates = useSelector((state) => getSearchValueByKey(state, "dates"));
  const [activedCategory, setActivatedCategory] = useState(null);
  const [hoursToOpen, setHoursToOpen] = useState(0);

  useEffect(() => {
    if (categories && categories.length > 0 && !activedCategory) {
      setActivatedCategory("all");
    }
  }, [categories]);

  const handleChange = (value) => {
    dispatch(setSearchByName(value));
  };

  useEffect(() => {
    if (quoteSummary.period === PERIODS.hour) {
      const fromHours =
        JSON.parse(openingHours)[
          `hours_${renderCustomFormatDate(
            dates.from,
            "dddd"
          ).toLocaleLowerCase()}`
        ];
      const actualTime = moment();
      const closedTime = moment(fromHours[0].to, "HH:mm");
      const openTime = moment(fromHours[0].from, "HH:mm");
      let timeDifference;
      let hours;
      if (isRental(supplierData._id, RENTALS.ananda)) {
        timeDifference = closedTime.diff(actualTime, "hours");
        hours = 1;
      } else {
        timeDifference = openTime.diff(actualTime, "hours");
        hours = 0;
      }
      timeDifference <= hours &&
        dispatch(
          modifyValueSearch("dates", {
            from: addDays(new Date(), 1),
            to: addDays(new Date(), 1),
          })
        );
      setHoursToOpen(timeDifference);
    }
  }, [quoteSummary?.period]);

  const handleOnCategoryActivity = (category) => {
    setActivatedCategory(category);
    trackEvent(clickEvents.SELECT_CATEGORY, {
      page: "Shop",
      category: category,
      supplierId: supplierData._id,
      supplierName: supplierData.name,
    });
    const element = document.getElementById(category);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const handleOnActionClick = () => {
    trackEvent(clickEvents.NAVIGATE_ADD_MEMBERS, {
      page: "Shop",
      supplierId: supplierData._id,
      supplierName: supplierData.name,
    });
    handleOnSummaryClick();
  };

  if (isLoading) {
    return (
      <SkeletonContainer>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </SkeletonContainer>
    );
  }

  const renderEmpty = () => {
    if (isEmpty(productsByCategory)) {
      return (
        <SkeletonContainer>
          <NoProductsContainer>
            <Paragraph>
              {t(`noEquipmentForActivity`)} "{searchName}"
            </Paragraph>
          </NoProductsContainer>
        </SkeletonContainer>
      );
    }
  };
  const renderSearch = () => {
    return <SearchBarProducts handleChange={handleChange} />;
  };

  return (
    <Container>
      <DateInputContainer
        period={quoteSummary.period}
        hoursToOpen={hoursToOpen}
      />
      <ProductList
        categories={categories}
        handleOnCategoryActivity={handleOnCategoryActivity}
        activedCategory={activedCategory}
        productsByCategory={productsByCategory}
        renderSearch={renderSearch}
        isMobile={isMobile}
        renderEmpty={renderEmpty}
        handleOnSubstractProduct={handleOnSubstractProduct}
        handleOnAddProduct={handleOnAddProduct}
        handleOnDetailsClick={handleOnDetailsClick}
        isClosed={isClosed}
        quoteSummary={quoteSummary}
        itemsLength={itemsLength}
        handleOnActionClick={handleOnActionClick}
        pricingData={pricingData}
        selectedProducts={selectedProducts}
        isPricingFetching={isPricingFetching}
        discounts={discounts}
        supplierId={supplierData?._id}
      />
    </Container>
  );
};

export default ProductContainer;
