import { Select } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import baseTheme from "../../../../config/baseTheme";

const Container = styled.div`
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.borders.gray}` : "none"};
  background-color: white;
  display: flex;
  flex-direction: row;
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  align-items: center;
  box-shadow: ${({ bordered }) =>
    bordered ? "1px 3px 10px rgb(0 0 0 / 0.1)" : "none"};
`;

const Label = styled.span`
  font-size: 12px;
  color: ${baseTheme.text.gray};
  font-weight: 400;
  text-align: left;
`;

const Wrapper = styled.div`
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: ${({ mobile }) => (mobile ? "100% !important" : "auto")};
  .ant-select-selector {
    font-size: 14px !important;
    padding-left: 0px !important;
  }
`;

const TimeQuantityPicker = ({
  label,
  style,
  onChange,
  values = [],
  bordered,
}) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();
  const [value, setValue] = useState(null);

  const handleOnChange = (val) => {
    setValue(val);
    onChange(val);
  };

  return (
    <Container
      style={{ ...style }}
      onClick={(e) => e.stopPropagation()}
      bordered={bordered}
      mobile={isMobile}
    >
      <Wrapper mobile={isMobile}>
        <Label>{label}</Label>
        <StyledSelect
          mobile={isMobile}
          onClick={(e) => e.stopPropagation()}
          value={value}
          onChange={handleOnChange}
          options={values}
          bordered={false}
          placeholder={t("quantity")}
          size="small"
          dropdownStyle={{ minWidth: 90, width: 150 }}
          style={{
            width: 180,
            backgroundColor: "white",
            textAlign: "left",
            paddingLeft: 0,
            borderRadius: 10,
          }}
        />
      </Wrapper>
    </Container>
  );
};

export default TimeQuantityPicker;
