import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "antd/es/button";
import { BookOutlined } from "@ant-design/icons";
import LayoutWithDataContainer from "../../containers/LayoutWithDataContainer";
import BannerCollapsed from "./components/BannerCollapsed/BannerCollapsed";
import ProductModal from "./components/ProductModal/ProductModal";
import QuoteBuilder from "./components/QuoteBuilder/QuoteBuilder";
import ShopWrapper from "./components/ShopWrapper/ShopWrapper";
import QuoteContainer from "./containers/QuoteContainer/QuoteContainer";
import PricingContainer from "./containers/PricingContainer/PricingContainer";
import {
  getSupplierData,
  getWebsiteContentConfig,
} from "../../Redux/Selectors/appConfigSelector";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../services/mixpanel";
import usePeriod from "../../hooks/usePeriod";
import { RENTALS, isRental } from "../../helpers/rentals";
import BannerRenderContainer from "../../components/BannerComponent/BannerRenderContainer";

const Container = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  border: ${({ theme }) => `1px solid ${theme.borders.gray}`};
`;

const ShopPageScreen = ({ isModalVisible, handleOnDismissModal, isIframe }) => {
  usePeriod();
  const { t } = useTranslation();
  const supplierData = useSelector(getSupplierData);
  const content = useSelector(getWebsiteContentConfig, shallowEqual);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "ShopPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const renderProductModal = () => {
    return (
      <ProductModal visible={isModalVisible} onClose={handleOnDismissModal} />
    );
  };

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      {!isIframe && (
        <BannerCollapsed bgImage={content?.headerBackgroundImage} />
      )}
      <Container>
        <PricingContainer>
          {({
            pricingData,
            isPricingFetching,
            discount: {
              handleApplyDiscount,
              handleRemoveDiscount,
              discountError,
            },
          }) => {
            const discountsProps = {
              onApplyDiscount: handleApplyDiscount,
              onRemoveDiscount: handleRemoveDiscount,
              discountError,
            };
            return (
              <QuoteContainer>
                {({
                  productsByCategory,
                  categories,
                  handleOnSubstractProduct,
                  handleOnAddProduct,
                  quoteSummary,
                  daysQuantity,
                  toDate,
                  searchName,
                  itemsLength,
                  isProductsFetching,
                  handleOnDetailsClick,
                  handleOnDismissModal,
                  supplierData,
                  activities,
                  selectedProducts,
                  isModalVisible,
                  handleOnSummaryClick,
                  dates,
                }) => {
                  return (
                    <ShopWrapper
                      renderExtra={() =>
                        renderProductModal(isModalVisible, handleOnDismissModal)
                      }
                      itemsLength={itemsLength}
                      pricingData={pricingData}
                      quoteSummary={quoteSummary}
                      selectedProducts={selectedProducts}
                      isPricingFetching={isPricingFetching}
                      handleOnSummaryClick={handleOnSummaryClick}
                      buttonLabel={t("continue")}
                      dates={dates}
                      discounts={discountsProps}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 20,
                          backgroundColor: "white",
                          gap: 10,
                        }}
                      >
                        {isRental(
                          supplierData._id,
                          RENTALS.highmountain_santiago
                        ) && (
                          <a
                            href="https://ventashighmountain.cl/catalogo-arriendo"
                            target="_blank"
                            rel="noreferrer"
                            style={{ alignSelf: "end", marginRight: 10 }}
                          >
                            <Button type="primary" icon={<BookOutlined />}>
                              Ver catálogo
                            </Button>
                          </a>
                        )}
                        <BannerRenderContainer screen="productsScreen" />
                      </div>
                      <QuoteBuilder
                        productsByCategory={productsByCategory}
                        categories={categories}
                        supplierData={supplierData}
                        handleOnAddProduct={handleOnAddProduct}
                        handleOnSubstractProduct={handleOnSubstractProduct}
                        quoteSummary={quoteSummary}
                        daysQuantity={daysQuantity}
                        toDate={toDate}
                        isProductsFetching={isProductsFetching}
                        handleOnDetailsClick={handleOnDetailsClick}
                        activities={activities}
                        visible={!isProductsFetching}
                        pricingData={pricingData}
                        isPricingFetching={isPricingFetching}
                        itemsLength={itemsLength}
                        selectedProducts={selectedProducts}
                        handleOnSummaryClick={handleOnSummaryClick}
                        searchName={searchName}
                        dates={dates}
                        discounts={discountsProps}
                      />
                      <ProductModal
                        visible={isModalVisible}
                        onClose={handleOnDismissModal}
                      />
                    </ShopWrapper>
                  );
                }}
              </QuoteContainer>
            );
          }}
        </PricingContainer>
      </Container>
    </LayoutWithDataContainer>
  );
};

export default ShopPageScreen;
