import moment from "moment";
import React from "react";
import styled from "styled-components";
import BootstrapIcon from "../../../../../components/BootstrapIcon/BoostrapIcon";
import baseTheme from "../../../../../config/baseTheme";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getAppConfigState } from "../../../../../Redux/Selectors/appConfigSelector";
import { useSelector } from "react-redux";

const Container = styled.div`
  text-align: ${({ align }) => align};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  cursor: pointer;
`;
const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  padding: 0;
  margin: 0;
  line-height: 1.2;
`;

const Label = styled.span`
  font-size: 12px;
  color: ${baseTheme.text.gray};
  font-weight: 400;
`;

const Date = styled.span`
  font-size: 14px;
`;

const DateInput = ({ label, value, align = "center", onClick }) => {
  const {
    data: { theme },
  } = useSelector(getAppConfigState);
  const isMobile = useIsMobile();
  return (
    <Container align={align} mobile={isMobile} onClick={onClick}>
      <BootstrapIcon
        iconName="calendar"
        style={{ fontSize: 18, color: theme.colors.primary }}
      />
      <StyledUl>
        <StyledLi>
          <Label>{label}</Label>
        </StyledLi>
        <StyledLi>
          <Date>{moment(value).format("DD/MM/YYYY")}</Date>
        </StyledLi>
      </StyledUl>
    </Container>
  );
};

export default DateInput;
