import { all, spawn, call } from "redux-saga/effects";
import appConfigSaga from "./appConfigSaga";
import highlightProductsSaga from "../../features/HomePage/redux/sagas/highlightProductsSaga";
import quoteSupplierSaga from "../../features/ShopPage/redux/sagas/QuoteShopSaga";
import variantsSaga from "./variantsSaga";
import transactionSaga from "../../features/TransactionPage/TransactionSaga";
import exchangeConverterSaga from "../../features/PaymentPage/redux/paymentSaga";
import transactionPaymentSaga from "../../features/PaymentPage/redux/transactionPaymentSaga";
import quoteSaga from "../../features/QuotePage/redux/QuoteSaga";
import experiencesSaga from "../../features/ExperiencesPage/redux/sagas/ExperiencesSaga";
import availabilitySaga from "../../features/ExperiencesPage/redux/sagas/availabilitySaga";
import availabilityShopSaga from "../../features/ShopPage/redux/sagas/AvailabilitySaga";
import spacesSaga from "../../features/SpacesPage/redux/sagas/spacesSaga";
import checkinSaga from "../../features/CheckInPage/redux/checkinSaga";
import preSaleSaga from "../../features/PreSalePage/PreSaleSaga";
import reviewsSaga from "../../features/HomePage/redux/sagas/reviewsSaga";

export default function* rootSaga() {
  const sagas = [
    quoteSaga,
    appConfigSaga,
    highlightProductsSaga,
    checkinSaga,
    reviewsSaga,
    quoteSupplierSaga,
    variantsSaga,
    transactionSaga,
    exchangeConverterSaga,
    transactionPaymentSaga,
    experiencesSaga,
    availabilitySaga,
    availabilityShopSaga,
    spacesSaga,
    preSaleSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
