import React from "react";
import QuoteBuilderCategory from "../../QuoteBulderCategory/QuoteBuilderCategory";
import QuoteBuilderRow from "../../QuoteBuilderRow/QuoteBuilderRow";
import FloatingSummary from "../../FloatingSummary/FloatingSummary";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RENTALS, isRental } from "../../../../../helpers/rentals";

const Container = styled.div`
  border-top: ${({ theme, disabledBorder }) =>
    !disabledBorder && `1px solid ${theme.borders.gray}`};
  margin-top: 10px;
`;
const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
`;

const TitleContainer = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  scroll-margin: 80px;
  display: flex;
  justify-content: start;
  gap: 8px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-radius: 3px;
  width: ${({ isMobile }) => (isMobile ? "100%" : "60%")};
`;

const ProductList = ({
  renderSearch,
  renderEmpty,
  categories,
  handleOnCategoryActivity,
  activedCategory,
  productsByCategory,
  isMobile,
  handleOnSubstractProduct,
  handleOnAddProduct,
  handleOnDetailsClick,
  isClosed,
  quoteSummary,
  itemsLength,
  handleOnActionClick,
  pricingData,
  selectedProducts,
  isPricingFetching,
  discounts,
  supplierId,
}) => {
  const { t } = useTranslation();
  const renderProducts = (title) => {
    return productsByCategory[title].map((item) => (
      <QuoteBuilderRow
        key={item._id}
        productId={item._id}
        title={item.title}
        subtitle={item.subtitle}
        price={item.convertedPrice}
        thumbnail={item.images?.length > 0 ? item.images[0] : null}
        promos={item.promo}
        currency={item.convertedCurrency}
        handleOnSubstractProduct={handleOnSubstractProduct}
        handleOnAddProduct={handleOnAddProduct}
        quantity={quoteSummary.products[item._id] || 0}
        handleOnDetailsClick={handleOnDetailsClick}
        isClosed={isClosed}
        supplierName={item.owner?.name}
        type={item?.type}
        minimumPeriodQuantity={item.minimumPeriodQuantity}
        rentalPeriodType={item.rentalPeriodType}
        category={item.category?.value}
      />
    ));
  };

  return (
    <Container disabledBorder={isRental(supplierId, RENTALS.labarraca)}>
      <QuoteBuilderCategory
        categories={categories}
        onClick={handleOnCategoryActivity}
        activedCategory={activedCategory}
        productsByCategory={productsByCategory}
      />
      {renderSearch()}
      <Body>
        <ItemsContainer isMobile={isMobile}>
          {categories.map((title) => {
            return (
              <div style={{ paddingLeft: 10, paddingRight: 10 }} key={title}>
                <TitleContainer id={title}>
                  <Title>{t(`categoriesList.${title}`)}</Title>
                </TitleContainer>
                {renderProducts(title)}
              </div>
            );
          })}
          {renderEmpty()}
        </ItemsContainer>
        {!isMobile && (
          <div
            style={{
              width: "40%",
              paddingTop: 30,
              paddingLeft: 40,
              marginRight: 15,
            }}
          >
            <FloatingSummary
              visible={true}
              itemsLength={itemsLength}
              pricingData={pricingData}
              period={quoteSummary?.period}
              selectedProducts={selectedProducts}
              isPricingFetching={isPricingFetching}
              expandedDefault
              expandable={false}
              offset={{
                offsetTop: 120,
              }}
              onActionClick={handleOnActionClick}
              discounts={discounts}
            />
          </div>
        )}
      </Body>
    </Container>
  );
};

export default ProductList;
