import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import TimeQuantityPicker from "../../../../components/TimeQuantityPicker/TimeQuantityPicker";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { Item, Row } from "./PeriodCalendars.styles";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const HourCalendarInput = ({
  from,
  align,
  handleOnTimeChange,
  fromHours,
  onClick,
}) => {
  const [timeQuantity, setTimeQuantity] = useState(null);
  const isMobile = useIsMobile();
  const [fromTimeSelected, setFromTimeSelected] = useState(null);
  const { t } = useTranslation("shop");

  const handleTimeQuantityChange = (value) => {
    setTimeQuantity(parseInt(value));
  };

  const handleFromTimeChange = (key, value) => {
    setFromTimeSelected(value);
  };

  useEffect(() => {
    if (timeQuantity && fromTimeSelected) {
      handleOnTimeChange("pickupTime", fromTimeSelected);
      handleOnTimeChange(
        "dropoffTime",
        `${Number(fromTimeSelected?.slice(0, 2)) + Number(timeQuantity)}:00`
      );
    }
  }, [timeQuantity, fromTimeSelected]);

  return (
    <Container>
      <Row mobile={isMobile}>
        <Item mobile={isMobile}>
          <DateInput
            label={t("startDate")}
            value={from}
            align={align}
            onClick={onClick}
          />
        </Item>
        <Item mobile={isMobile}>
          <TimeQuantityPicker
            onChange={handleTimeQuantityChange}
            values={[
              {
                label: "1 hora",
                value: "1",
              },
              {
                label: "2 horas",
                value: "2",
              },
              {
                label: "4 horas",
                value: "4",
              },
              {
                label: "Todo el día",
                value: "8",
              },
            ]}
            label={t("quantityByPeriods.hour_quantity")}
          />
        </Item>
        <Item mobile={isMobile}>
          <TimePicker
            label={t("startDate")}
            onChange={handleFromTimeChange}
            keyVal="pickupTime"
            fromTime={fromHours?.map((hour) => hour.from.split(":")[0]).flat()}
            toTime={fromHours?.map((hour) => hour.to.split(":")[0]).flat()}
            disabled={timeQuantity === null}
            dateSelected={from}
            amountSelected={timeQuantity}
          />
        </Item>
      </Row>
    </Container>
  );
};

export default HourCalendarInput;
