import React from "react";
import styled from "styled-components";
import { PERIODS } from "../../../../../helpers/tools";
import DaysCalendarInput from "./PeriodCalendars/DaysCalendarInput";
import HourCalendarInput from "./PeriodCalendars/HourCalendarInput";

const CalendarInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ bordered }) => (bordered ? "center" : "space-between")};
  align-items: center;
  border: ${({ bordered }) => (bordered ? "1px solid #ebebeb" : "none")};
  border-radius: 50px;
  background-color: white;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 5px;
  padding-right: 5px;
  flex-wrap: wrap;
`;

const CalendarInputWrapper = ({
  from,
  to,
  bordered = true,
  align,
  handleOnTimeChange,
  fromHours,
  toHours,
  period = PERIODS.day,
  onClick,
}) => {
  return (
    <CalendarInputContainer bordered={bordered}>
      {period === PERIODS.day && (
        <DaysCalendarInput
          from={from}
          to={to}
          align={align}
          handleOnTimeChange={handleOnTimeChange}
          fromHours={fromHours}
          toHours={toHours}
          onClick={onClick}
        />
      )}
      {period === PERIODS.hour && (
        <HourCalendarInput
          from={from}
          align={align}
          handleOnTimeChange={handleOnTimeChange}
          fromHours={fromHours}
          onClick={onClick}
        />
      )}
    </CalendarInputContainer>
  );
};

export default CalendarInputWrapper;
