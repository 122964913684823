import { isEmpty, isNil } from "lodash";
import { EXPECTED_SORT_CATEGORIES } from "./constants";

export const groupReferrals = (referrals = []) => {
  const groupedReferrals = {};

  referrals?.forEach((referral) => {
    const { category, name, _id } = referral;
    if (!groupedReferrals[category]) {
      groupedReferrals[category] = [{ name, _id }];
    } else {
      const existingReferral = groupedReferrals[category].find(
        (r) => r.name === name
      );
      if (!existingReferral) {
        groupedReferrals[category].push({ name, _id });
      }
    }
  });

  return groupedReferrals;
};

export const getInitialReferral = (
  groupedReferrals = {},
  selectedCategory,
  referralName
) => {
  if (
    isEmpty(groupedReferrals) ||
    isNil(selectedCategory) ||
    isNil(referralName)
  ) {
    return null;
  }
  return (
    groupedReferrals[selectedCategory]?.find(
      (referral) => referral.name === referralName
    ) ?? null
  );
};

export const referralIdentifier = (referralCategory, referral) => {
  const isReferralId = /^[A-Za-z]+$/.test(referralCategory);
  return isReferralId ? referral : referralCategory;
};

export const sanitizeReferrals = (referrals = []) => {
  if (isEmpty(referrals)) return null;
  return referrals?.filter((referral) => referral?.visibleOnWebsite);
};

export const sortCategories = (categories = {}) => {
  const sortedCategories = Object.keys(categories)
    .sort((a, b) => {
      if (a === EXPECTED_SORT_CATEGORIES.HOTEL) return -1;
      if (b === EXPECTED_SORT_CATEGORIES.HOTEL) return 1;
      return a.localeCompare(b);
    })
    .filter((category) => category !== EXPECTED_SORT_CATEGORIES.HOTEL);

  if (categories[EXPECTED_SORT_CATEGORIES.HOTEL]) {
    sortedCategories.unshift(EXPECTED_SORT_CATEGORIES.HOTEL);
  }

  return sortedCategories;
};
