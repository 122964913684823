import { Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import baseTheme from "../../../../config/baseTheme";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetPickUpAndDropOfTime } from "../../../../Redux/Actions/searchActions";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  getAppConfigState,
  getSupplierData,
} from "../../../../Redux/Selectors/appConfigSelector";
import { RENTALS, isRental } from "../../../../helpers/rentals";

const Container = styled.div`
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.borders.gray}` : "none"};
  border-radius: 30px;
  background-color: white;
  display: flex;
  flex-direction: row;
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  align-items: center;
  box-shadow: ${({ bordered }) =>
    bordered ? "1px 3px 10px rgb(0 0 0 / 0.1)" : "none"};
`;

const Label = styled.span`
  font-size: 12px;
  color: ${baseTheme.text.gray};
  font-weight: 400;
  text-align: left;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  width: ${({ mobile }) => (mobile ? "100%" : "auto")};
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: ${({ mobile }) => (mobile ? "100% !important" : "auto")};
  .ant-select-selector {
    font-size: 14px !important;
  }
`;

const TimePicker = ({
  label,
  style,
  onChange,
  keyVal,
  fromTime,
  toTime,
  bordered,
  disabled = false,
  dateSelected,
  amountSelected,
}) => {
  const { t } = useTranslation("shop");
  const {
    data: { theme },
  } = useSelector(getAppConfigState);
  const dispatch = useDispatch();
  const supplierData = useSelector(getSupplierData);
  const isMobile = useIsMobile();
  const [timeValue, setTimeValue] = useState(null);
  useEffect(() => {
    if (fromTime && toTime) {
      const from = Number(fromTime[0]);
      const to = Number(toTime[0]);
      const timeFrame = Array.from(
        { length: to - from + 1 },
        (_, index) => index + from
      );
      !timeFrame.includes(timeValue) && resetTime();
    }
  }, [toTime]);

  useEffect(() => {
    if (timeValue !== null && amountSelected) {
      resetTime();
      onChange(keyVal, null);
    }
  }, [amountSelected]);

  const formatHour = (time) => {
    return moment().set("hour", time).format("HH:00");
  };
  const resetTime = () => {
    setTimeValue(null);
    dispatch(resetPickUpAndDropOfTime(keyVal));
  };
  if (!fromTime || !toTime) return null;
  const arrayRange = (start, stop, step) => {
    if (
      moment().format("DD-MM-YY") === moment(dateSelected).format("DD-MM-YY")
    ) {
      start =
        Number(moment().format("HH")) > Number(start)
          ? moment().format("HH")
          : start;
    }
    const parsedStart = Number(start);
    const parsedStop = Number(amountSelected ? stop - amountSelected : stop);
    return Array.from(
      { length: (parsedStop - parsedStart) / step + 1 },
      (value, index) => parsedStart + index * step
    );
  };
  const timeOptions = fromTime
    .map((from, index) =>
      arrayRange(from, toTime[index], 1).map((time) => ({
        label: formatHour(time),
        value: time,
      }))
    )
    .flat();

  const timeLabarracaOptions = () => {
    return [
      {
        label: t("morning"),
        value: 10,
      },
      {
        label: t("afternoon"),
        value: 17,
      },
    ];
  };

  const handleOnChange = (val) => {
    const parsedValue = formatHour(val);
    setTimeValue(val);
    onChange(keyVal, parsedValue);
  };

  return (
    <Container
      style={{ ...style }}
      onClick={(e) => e.stopPropagation()}
      bordered={bordered}
      mobile={isMobile}
    >
      <BootstrapIcon
        iconName="clock"
        style={{ fontSize: 22, color: theme.colors.primary }}
      />
      <Wrapper mobile={isMobile}>
        <Label>{label}</Label>
        <StyledSelect
          mobile={isMobile}
          notFoundContent={<span>No hay horarios disponibles</span>}
          onClick={(e) => e.stopPropagation()}
          value={timeValue}
          onChange={handleOnChange}
          options={
            isRental(supplierData?._id, RENTALS.labarraca)
              ? timeLabarracaOptions()
              : timeOptions
          }
          bordered={false}
          placeholder={t("time")}
          size="small"
          dropdownStyle={{ minWidth: 90, width: 150 }}
          style={{
            width: 110,
            backgroundColor: "white",
            textAlign: "left",
            paddingLeft: 0,
            borderRadius: 10,
          }}
          disabled={disabled}
        />
      </Wrapper>
    </Container>
  );
};

export default TimePicker;
