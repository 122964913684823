import { spPromotionExperiencesList } from "../features/ExperiencesPage/config/spAventuasConfig";

export const RENTALS = {
  ananda: "6478ca93d0a429457ba3d79e",
  travesiaCatedral: "6442d34acc618fcc23e2c3f2",
  alborde: "63f7a377c658b8124f93053e",
  windfly: "6269925a5858c887e83252a8",
  staging: "5fda9acdd2f54e764b8ff123",
  highmountain_santiago: "63a0b3c235c44eae995d930a",
  highmountain_curacautin: "6495b4314860d118ff04912f",
  labarraca: "606f4426a2a0f2002083c4f1",
  fronteraSur: "642c2f3fabd67379c738540b",
  skiinchile: "6255b28bc07290ff360f8540",
  spAventuras: "6564c51d1626950be4c763dd",
  campingLaIndia: "65708e6d9361b88f38b93c31",
  intiAventura: "65b95a0ac51ff028ebb173f5",
  bicitarte: "607451a46eeaa7001a457c05",
  highPeak: "65d79962761e6797b82b3583",
  rumboNorte: "661d75331724655cf9fc62c7",
  vertical: {
    verticalBariloche: "65b2affa1ed476972fa06cc4",
    verticalLosMolles: "65b3ed39170547804e508572",
    verticalMalargue: "65b3efa11705470a2d50863c",
    verticalLasLeñas: "65b3f06c1ed4764ee1a0fcf5",
    verticalSanMartin: "65b3f128170547d1af5086cf",
    verticalUshuaia: "65b3f35e170547cb13508794",
    verticalCordoba: "65b3f3d91ed4763cf7a0fe4d",
    verticalBuenosAires: "65b3f52217054793c75088b7",
    verticalRosario: "65b3f5cd17054765fe5089ad",
    verticalSanJuan: "65b3f630100b356cdce7404a",
    verticalCasaCentral: "65d4e888e120b95a634a4c2b",
  },
  circuitoChico: {
    circuitoChicoSede: "5fc9871da5a5880019b4bdf7",
    circuitoChico: "655cfdc58ab6d95d7e0812c6",
  },
  extremoOutdoor: "61c9befe3c19fe7814dd7ff0",
  villavicencio: "66a108b912555d17badbe60d",
};

export const isRental = (id, rentalConstantId) => {
  return id === rentalConstantId;
};

export const isAnyHighMountain = (id) => {
  return (
    isRental(id, RENTALS.highmountain_santiago) ||
    isRental(id, RENTALS.highmountain_curacautin)
  );
};

export const isSoyRosario = (id) => {
  return isRental(id, RENTALS.staging);
};

export const getSpExperiencePromotion = (supplierId, region) => {
  const isSpAventuras = isRental(supplierId, RENTALS.spAventuras);

  if (isSpAventuras && region === "argentina") {
    return spPromotionExperiencesList;
  }
  return null;
};

export const isVertical = (id) => {
  return Object.values(RENTALS.vertical).some((rentalId) =>
    isRental(rentalId, id)
  );
};

export const isCircuitoChico = (id) => {
  return Object.values(RENTALS.circuitoChico).some((rentalId) =>
    isRental(rentalId, id)
  );
};
