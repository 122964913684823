import React from "react";
import styled from "styled-components";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { PERIODS } from "../../helpers/tools";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const Container = styled.div``;
const Date = styled.div``;
const StyledText = styled(Text)`
  font-size: 14px;
`;

const Dates = ({
  from,
  to,
  pickupTime,
  dropoffTime,
  format = "DD/MM/YY - HH:mm",
  period,
  daysQuantity,
}) => {
  const { t } = useTranslation("shop");
  const theme = useTheme();
  const renderHours = (time) => {
    if (!pickupTime || !dropoffTime) return null;
    return <StyledText>{time}hs</StyledText>;
  };

  if (period === PERIODS.hour) {
    return (
      <Container>
        <Date>
          <StyledText>{t("startDate")}:</StyledText>{" "}
          <StyledText strong>
            {moment(from).format(format)} - {renderHours(pickupTime)}
          </StyledText>
        </Date>
        <Date>
          <StyledText>{t("dropOffDate")}:</StyledText>{" "}
          <StyledText strong>
            {moment(to).format(format)} - {renderHours(dropoffTime)}
          </StyledText>
        </Date>
      </Container>
    );
  }

  return (
    <Container>
      <Date>
        <StyledText>{t("startDate")}:</StyledText>{" "}
        <StyledText strong>
          {moment(from).format(format)} - {renderHours(pickupTime)}
        </StyledText>
      </Date>
      <Date>
        <StyledText>{t("dropOffDate")}:</StyledText>{" "}
        <StyledText strong>
          {moment(to).format(format)} - {renderHours(dropoffTime)}
        </StyledText>
      </Date>
      <StyledText>
        <StyledText>{t("serviceDays")}:</StyledText>{" "}
        <Tooltip
          overlayStyle={{ fontSize: 14 }}
          title={t("daysQuantity_description")}
        >
          <StyledText strong>{t(period, { count: daysQuantity })}</StyledText>{" "}
          <InfoCircleOutlined style={{ color: theme.colors.primary }} />
        </Tooltip>
      </StyledText>
    </Container>
  );
};

export default Dates;
