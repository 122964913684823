import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "antd/es/button";
import Modal from "antd/es/modal/Modal";
import { PERIODS, buildSearchParams } from "../../../../helpers/tools";
import { modifyValueSearch } from "../../../../Redux/Actions/searchActions";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import CalendarContainer from "../../components/CalendarContainer/CalendarContainer";
import CalendarInput from "../../forms/AddPeopleForm/CalendarInput/CalendarInput";
import {
  getPrevDaysForBookingConfig,
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import { listRestrictedDates } from "../../../../helpers/supplierSettings";
import { getOpeningDays } from "../../../../helpers/supplier";

const DateInputContainer = ({ period = PERIODS.day, hoursToOpen }) => {
  const { t } = useTranslation(["shop", "common"]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const supplierSettings = useSelector(getSupplierSettings);
  const supplierData = useSelector(getSupplierData);
  const searchValues = useSelector(getSearchValues);
  const prevDaysForBooking = useSelector((state) =>
    getPrevDaysForBookingConfig(state, shallowEqual)
  );

  const opennedDaysOfWeek = getOpeningDays(
    supplierData.openingConfig,
    supplierData.openingHours
  );

  const handleOnSelect = (key, val) => {
    dispatch(modifyValueSearch(key, val));
  };

  useEffect(() => {
    navigate(
      `${location.pathname}?${buildSearchParams({
        ...searchValues,
        prevDaysForBooking,
        searchPath: location.search,
      })}`,
      {
        replace: true,
      }
    );
  }, [searchValues]);

  const resetModal = () => {
    setShowModal(false);
  };

  const renderModal = () => {
    //TODO: add if mobile
    return (
      <Modal
        open={showModal}
        width={800}
        title={t("common:calendar")}
        onCancel={resetModal}
        onOk={resetModal}
        footer={[
          <Button
            shape="round"
            size="large"
            type="primary"
            onClick={resetModal}
          >
            {t("common:apply")}
          </Button>,
        ]}
      >
        <CalendarContainer
          handleCloseModal={resetModal}
          handleOnSelect={handleOnSelect}
          months={2}
          direction={"horizontal"}
          restrictedDates={listRestrictedDates(
            supplierSettings?.restrictedDates
          )}
          opennedDaysOfWeek={opennedDaysOfWeek}
          period={period}
          hoursToOpen={hoursToOpen}
          prevDaysForBooking={prevDaysForBooking}
        />
      </Modal>
    );
  };

  return (
    <>
      <CalendarInput
        bordered={true}
        handleShowModal={() => setShowModal(true)}
        showDisclaimer={false}
        handleOnSelect={handleOnSelect}
        period={period}
        prevDaysForBooking={prevDaysForBooking}
      />
      {renderModal()}
    </>
  );
};

export default DateInputContainer;
