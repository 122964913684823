import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: white;
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.borders.gray};
  padding: 0px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  height: 50px;
  width: 100%;
`;

export const WrapperRight = styled(Wrapper)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const WrapperLeft = styled(Wrapper)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
