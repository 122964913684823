import { useDispatch } from "react-redux";
import { quoteBuildAddPassengers } from "../../ShopPage/redux/actions/shopActions";
import { useLocation, useNavigate } from "react-router-dom";
import { buildSearchParams } from "../../../helpers/tools";
import moment from "moment";

const ParticipantsContainer = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const onSubmitPassengers = (values) => {
    const clonedNames = [...values.names];
    // Remove main member of the booking
    clonedNames.shift();
    const passengers = clonedNames.reduce((acc, value, i) => {
      acc[i] = {
        id: i + 1,
        name: value,
      };
      return acc;
    }, {});
    dispatch(quoteBuildAddPassengers(passengers));

    navigate(
      `/checkin/checkout?${buildSearchParams({
        dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        pickupTime: "12:00",
        dropoffTime: "18:00",
        searchPath: location.search,
      })}`,
      {
        replace: true,
      }
    );
  };

  return children({ onSubmitPassengers });
};

export default ParticipantsContainer;
