import React from "react";
import { FormContainer } from "../CheckinScreen.styles";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPassengersData } from "../../ShopPage/redux/selectors/ShopSelectors";

const ParticipantsForm = ({ onSubmitPassengers }) => {
  const { t } = useTranslation("common");
  const participants = useSelector(getPassengersData);

  return (
    <FormContainer>
      <Form
        onFinish={onSubmitPassengers}
        initialValues={{
          names: participants.map((participant) => participant.name),
        }}
      >
        <Form.List name="names">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: t("shop:form.error", {
                          field: t("shop:form.name"),
                        }),
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      disabled={index === 0}
                      placeholder={t("shop:form.name")}
                      style={{
                        width: "90%",
                        marginRight: 10,
                      }}
                    />
                  </Form.Item>
                  {index !== 0 && fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  size="middle"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ fontSize: 14 }}
                >
                  {t("shop:passengers.addMember")}
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item noStyle>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            style={{ marginTop: 60 }}
          >
            {t("common:continue")}
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default ParticipantsForm;
