import { isEmpty } from "lodash";
import {
  RESET_SUMMARY,
  SET_BOOKING_PERIOD,
  SET_DATE,
  SET_EXPERIENCE_ID,
  SET_START_TIME,
  SUMMARY_ADD_ITEM,
  SUMMARY_ADD_TICKET,
  SUMMARY_SUBTRACT_ITEM,
  SUMMARY_SUBTRACT_TICKET,
} from "../actions/shopActions";

const INITIAL_STATE = {
  products: {},
  experienceId: null,
  dates: {
    daysQuantity: 1,
    from: new Date(),
    to: new Date(),
  },
  period: null,
  startTimeId: null,
  tickets: {},
};

const setItemQuantity = (products, productId, quantity) => {
  const productsCopy = { ...products };
  if (isEmpty(productId)) {
    return { ...productsCopy };
  }
  if (quantity > 0) {
    return {
      ...productsCopy,
      [productId]: quantity,
    };
  }
  delete productsCopy[productId];
  return productsCopy;
};

const summaryShopReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUMMARY_ADD_ITEM:
      return {
        ...state,
        products: setItemQuantity(
          state.products,
          payload.productId,
          state.products[payload.productId]
            ? state.products[payload.productId] + 1
            : 1
        ),
      };
    case SUMMARY_SUBTRACT_ITEM:
      return {
        ...state,
        products: setItemQuantity(
          state.products,
          payload.productId,
          state.products[payload.productId]
            ? state.products[payload.productId] - 1
            : 0
        ),
      };
    case SET_EXPERIENCE_ID:
      return {
        ...state,
        experienceId: payload.experienceId,
      };
    case SUMMARY_ADD_TICKET:
      return {
        ...state,
        tickets: setItemQuantity(
          state.tickets,
          payload.ticketRate,
          state.tickets[payload.ticketRate]
            ? state.tickets[payload.ticketRate] + 1
            : 1
        ),
      };
    case SUMMARY_SUBTRACT_TICKET:
      return {
        ...state,
        tickets: setItemQuantity(
          state.tickets,
          payload.ticketRate,
          state.tickets[payload.ticketRate]
            ? state.tickets[payload.ticketRate] - 1
            : 0
        ),
      };
    case SET_DATE:
      return {
        ...state,
        dates: {
          ...payload.dates,
        },
      };
    case SET_BOOKING_PERIOD:
      return {
        ...state,
        period: payload.period,
      };
    case SET_START_TIME:
      return {
        ...state,
        startTimeId: payload.id,
      };
    case RESET_SUMMARY:
      return {
        INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default summaryShopReducer;
